.menu_sider::-webkit-scrollbar {
    display: none;
}

.menu_sider {
    margin-top: 85px;
    height: calc(100% - 85px);
    padding-bottom: 150px !important;
    /* height: 100%; */
    overflow-y: scroll !important;
}
.ant-menu:first-child {
    margin-top: 0px !important;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #ef7d00;
}
.xp-body {
    height: 105% !important;
    margin-top: 30px;
}
.xp-body::-webkit-scrollbar {
    display: none;
}
.xp-breadcrumb {
    text-align: left;
    padding: 5px;
    padding-left: 10px;
    width: 100%;
    background-color: #ffffff;
    font-size: small;
    position: fixed;
    z-index: 10;
}
